import { Flex, Button, Combobox, useCombobox, TextInput, useMantineTheme } from '@mantine/core';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useSavedSearchValues } from 'context/SavedSearchFilterValuesContext';
import { useEffect, useRef, useState } from 'react';
import { Actions, trackAction } from 'utils/amplitude';
import getURLpathnameParam from 'utils/getURLpathnameParam';
import { useRouter } from 'next/router';
import classes from './GoogleAutocompleteInput.module.scss';
import { Crosshair } from '@phosphor-icons/react';

export const GoogleAutocompleteInput = () => {
  const {
    locationString,
    setLocationString,
    setModifiedFilter,
    setUserDeniedGeolocation,
    userDeniedGeolocation,
    setSearchFilterState,
  } = useSavedSearchValues();

  const theme = useMantineTheme();

  const formatUKPostcode = (postcode: string) => {
    // Adds correct spacing for uk postcode to allow autocomplete to search for postcodes entered with no spaces
    return postcode.replace(/(\d{1,2})(\d{1,2}[a-zA-Z]{1,2})/, '$1 $2');
  };

  const URLpathname = getURLpathnameParam(useRouter().pathname);

  const {
    ready,
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'gb' },
    },
    defaultValue: locationString,
    debounce: 300,
  });

  const [geolocationLoading, setGeolocationLoading] = useState(false);
  const [htmlGeolocation, setHtmlGeolocation] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelect = (suggestion: google.maps.places.AutocompletePrediction) => {
    // When the user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(suggestion.description, false);
    setLocationString(suggestion.description.split(',')[0].trim());
    clearSuggestions();
    inputRef?.current?.blur();

    // Get latitude and longitude via utility functions
    getGeocode({ address: suggestion.description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);

      setSearchFilterState((prevState) => ({
        ...prevState,
        coordinates: {
          lat,
          lng,
        },
      }));
    });
  };

  const getGeolocation = () => {
    if (navigator.geolocation) {
      setHtmlGeolocation(true);

      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          setSearchFilterState((prevState) => ({
            ...prevState,
            coordinates: pos,
          }));

          const geocoder = new google.maps.Geocoder();
          const latLng = new google.maps.LatLng(pos.lat, pos.lng);

          geocoder.geocode({ location: latLng }, (results, geocoderStatus) => {
            if (geocoderStatus === 'OK' && results?.[0]) {
              const address = results[0].formatted_address.split(',').reverse()[1].trim();
              setValue(address);
              setLocationString(address);
              setGeolocationLoading(false);
            } else {
              console.error('Reverse geocoding failed:', geocoderStatus);
              setGeolocationLoading(false);
            }
          });
        },
        (error: GeolocationPositionError) => {
          if (error.code === error.PERMISSION_DENIED) {
            // setUserDeniedGeolocation state if user has location permissions turned off in settings
            setUserDeniedGeolocation(true);
            console.log('User denied geolocation request.');
          } else {
            // Handle other error scenarios
            console.log('Geolocation error:', error.message);
          }

          setGeolocationLoading(false);
        },
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  const handleItemSubmit = (item: string) => {
    const newSelectedLocation = data.find(
      (suggestionObject) => suggestionObject.description === item,
    );

    if (newSelectedLocation) {
      return handleSelect(newSelectedLocation);
    }
  };

  const handleAutoSelection = () => {
    if (data.length === 1) {
      return handleSelect(data[0]);
    }
  };

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const options = data.map((suggestion) => {
    const {
      place_id: placeId,
      structured_formatting: { main_text: mainText, secondary_text: secondaryText },
    } = suggestion;

    return (
      <Combobox.Option
        value={suggestion.description}
        key={placeId}
        className={classes.suggestionItem}
      >
        <li
          key={placeId}
          data-cy={`${mainText} ${secondaryText}`}
          style={{ listStyleType: 'none' }}
        >
          <strong>{mainText}</strong> <small>{secondaryText}</small>
        </li>
      </Combobox.Option>
    );
  });

  // Set geolocation on page load if no initial value
  useEffect(() => {
    if (!value) {
      setGeolocationLoading(true);
      getGeolocation();
      setModifiedFilter(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex direction="column">
      <Combobox
        onOptionSubmit={(optionValue) => {
          handleItemSubmit(optionValue);
          trackAction(Actions.ENTERS_POSTCODE, {
            URLpathname,
          });
        }}
        withinPortal={false}
        store={combobox}
      >
        <Combobox.Target>
          <TextInput
            data-cy="location-input"
            placeholder="Add your location"
            value={value}
            onChange={(event) => {
              const enteredValue = event.currentTarget.value;
              setModifiedFilter(true);
              if (!htmlGeolocation) {
                const formattedValue = formatUKPostcode(enteredValue);
                setValue(formattedValue);
              }
              combobox.resetSelectedOption();
              combobox.openDropdown();
            }}
            onClick={() => combobox.openDropdown()}
            onFocus={() => {
              setUserDeniedGeolocation(false);
              setHtmlGeolocation(false);
            }}
            onBlur={() => {
              handleAutoSelection();
              combobox.closeDropdown();
            }}
            classNames={{
              input: classes.input,
            }}
            error={userDeniedGeolocation && 'Turn on location settings for your device.'}
            disabled={!ready}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleAutoSelection();
              }
            }}
          />
        </Combobox.Target>

        <Combobox.Dropdown hidden={data.length === 0}>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
      <Button
        variant="white"
        title="Request browser geolocation"
        className={classes.geolocationIcon}
        onClick={() => {
          setGeolocationLoading(true);
          getGeolocation();
          setModifiedFilter(true);
          trackAction(Actions.GEOLOCATION_SELECT, {
            URLpathname,
          });
        }}
        loading={geolocationLoading}
        leftSection={<Crosshair weight="bold" size={22} color={theme.colors.pink[5]} />}
      >
        Use current location
      </Button>
    </Flex>
  );
};
