import { gql } from '@apollo/client';

const getBasket = gql`
  query getBasket($basketId: UUID!) {
    getBasket(id: $basketId) {
      id
      step
      createdAt
      updatedAt
      activity {
        id
        slug
        name
        description
        ageRange
        ageMonthsStart
        ageMonthsEnd
        cloudinaryImageId
        blockSubtype
        subscriptionTrialType
        subscriptionTrialSessionCount
        subscriptionTrialPrice
        subscriptionOffDates
        subscriptionStart
        activityType
        isOnline
        ponchoPayAccepted
        hideEndTimes
        supplier {
          id
          name
          links {
            url
            name
          }
          additionalInformationCaptureEnabled
        }
        location {
          addressLine1
          addressLine2
          city
          country
          postCode
        }
        ageMonthsStart
        ageMonthsEnd
        socialProof {
          limitedSpots
          popular
        }
      }
      classes {
        id
        name
      }
      tickets {
        id
        classId
        ticket
        ticketName
        ticketPrice
        ticketProrataPrice
        ticketCapacity
        hasAddonOptions
        addons {
          attendee {
            id
            fullName
          }
          perSession {
            addonOption {
              id
              name
              description
              price
              availableSessions {
                id
                startTime
                endTime
                date
              }
            }
            note
            availableSessions {
              id
              date
              startTime
              endTime
            }
            selectedSessions {
              id
              startTime
              endTime
              date
            }
          }
          perBooking {
            addonOption {
              id
              name
              description
              price
            }
            note
            quantity
          }
        }
        attendees {
          id
          fullName
          birthDate
          isAdult
        }
        product {
          dateRange
          weekdays
          startTime
          endTime
          timeGroup
          spotsLeft
          checkoutKey
          checkoutValue
          checksum
          singleSessionDate
          blockLastSessionDate
          blockFirstSessionDate
          blockFirstPaidSessionDate
          numberOfSessionsAvailable
          subscriptionNextSessionDate
          allBlockDates {
            date
            isInPast
            startTime
            endTime
            spotsLeft
          }
          multipleSessionDates
          prorata {
            remainingSessions
            firstPaidSession
            trialSessions
          }
        }
        sessions {
          id
          date
          dayOfTheWeek
          startTime
          endTime
        }
        subscriptionTrialSelected
        subscriptionTrialEndTimestamp
        subscriptionOption {
          id
          schedule {
            id
            startTime
            endTime
            capacity
            dayOfTheWeek
          }
          scheduleSummary
          ticketPrice
          stripeProductId
          stripePriceId
        }
        amount
        pricingPolicy
        blockTrialSelected
        blockTrialType
        blockTrialSessionCount
        blockTrialPrice
      }
      guardian {
        id
        children {
          id
          fullName
          birthDate
          isAdult
        }
      }
      guest {
        id
        fullName
        email
        phoneNumber
        isDataConsentGiven
        marketingOptIn
        children {
          id
          fullName
          birthDate
          isAdult
        }
      }
      originalAmount
      discount
      finalAmount
      promotion {
        code
      }
      isMarketingConsentGiven
      supplierLinksAccepted
      supplierMarketingConsentGiven
      questionAnswers {
        id
        requiredBeforeCheckout
        question {
          id
          type
          title
          isPerAttendee
          shortTitle
          choices
        }
        attendeeAnswers {
          answer
          attendeeId
          previousAnswer
        }
        bookerAnswer
        previousBookerAnswer
      }
      ineligibleAttendeeForBlockTrial
    }
  }
`;

export default getBasket;
