import { gql } from '@apollo/client';

const GetActivityBookingsByIds = gql`
  query GetActivityBookingsByIds($activityBookingIds: [UUID]) {
    activityBookingsByIds(activityBookingIds: $activityBookingIds) {
      id
      checkoutSessionId
      basket {
        id
        createdAt
        updatedAt
        status
        step
        supplierLinksAccepted
        isMarketingConsentGiven
        metadata
        originalAmount
        finalAmount
        discount
        supplierMarketingConsentGiven
        ineligibleAttendeeForBlockTrial
      }
      promotion {
        code
      }
      activity {
        id
        name
        isOnline
        supplier {
          id
          name
          slug
        }
        location {
          id
          addressLine1
          addressLine2
          city
          country
          postCode
        }
        activityType
        hideEndTimes
        subscriptionInfo
      }
      guardian {
        id
      }
      guest {
        id
      }
      finalAmount
      originalAmount
      bookingType
      status
      numberOfBookedTickets
      bookedClasses {
        id
        name
        bookedTickets {
          id
          originalAmount
          ticketNameAtBooking
          ticketPriceAtBooking
          firstSession {
            id
          }
          attendees {
            id
            fullName
          }
          addonsByAttendee {
            attendee {
              id
              fullName
            }
            sessionAddons {
              name
              price
              quantity
            }
            bookingAddons {
              name
              price
              quantity
            }
          }
          numberOfSessions
          sessions {
            id
            sessionStart
            sessionEnd
            sessionNumber
            totalSessions
            status
            sessionAddons {
              name
              description
              price
              note
              addonId
              status
            }
            bookingAddons {
              name
              description
              price
              note
              addonId
              status
              quantity
            }
          }
          blockFirstPaidSessionDate
          blockTrialType
          blockTrialSessionCount
          blockTrialPrice
        }
      }
      bookedTickets {
        id
        ticketNameAtBooking
        ticketPriceAtBooking
        attendees {
          id
          fullName
        }
        addonsByAttendee {
          attendee {
            id
            fullName
          }
          sessionAddons {
            name
            price
            quantity
          }
          bookingAddons {
            name
            price
            quantity
          }
        }
        ticket {
          id
          name
          price
          ticketType
        }
        subscriptionTrialSessionCount
        firstSessionDate
        numberOfSessions
        subscriptionStartTime
        subscriptionEndTime
        subscriptionDayOfWeek
        subscriptionOffDates
        subscriptionTrialSelected
        subscriptionTrialEnd
        subscriptionTrialPrice
        sessions {
          id
          sessionStart
          sessionEnd
          sessionNumber
          totalSessions
          status
          isFreeTrialSession
          isPaidTrialSession
        }
      }
    }
  }
`;

export default GetActivityBookingsByIds;
