export const APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

// prettier-ignore
export const load = () => {
  (function(){const w=window;const ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{const d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;const l=function(){const s=d.createElement('script');s.type='text/javascript';s.defer=true;s.src='https://widget.intercom.io/widget/' + APP_ID;const x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

export const update = () => {
  window && window.Intercom && window.Intercom('update');
};

export const setUser = (id, name, utmParams, intercomHash) => {
  if (global.window) {
    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: APP_ID,
      user_id: id,
      // user_hash: intercomHash,
      name,
      //   utm_source: utmParams.utm_source ?? '',
      //   utm_campaign: utmParams.utm_campaign ?? '',
      //   utm_medium: utmParams.utm_medium ?? '',
    };

    update();
  }
};

export const showNewIntercomMessage = () => {
  window && window.Intercom && window.Intercom('showNewMessage');
};

export const showIntercomSurvey = (surveyId, delay = 5000) => {
  if (!window || !window.Intercom || !surveyId) {
    return;
  }
  if (delay) {
    setTimeout(() => {
      window.Intercom('startSurvey', surveyId);
    }, delay);
  } else {
    window.Intercom('startSurvey', surveyId);
  }
};

export const trackEvent = (eventName, eventMetadata = {}) => {
  window && window.Intercom && window.Intercom('trackEvent', eventName, eventMetadata);
};

// Initializes Intercom
export const boot = (options = {}) => {
  window && window.Intercom && window.Intercom('boot', { app_id: APP_ID, ...options });
};
