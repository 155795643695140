import { gql } from '@apollo/client';

const GetBookingById = gql`
  query getBookingById($bookingId: UUID!) {
    activityBookingById(bookingId: $bookingId) {
      id
      checkoutSessionId
      basketId
      originalAmount
      additionalInformationCapturedLastAt
      additionalInformationCaptured
      promotion {
        code
      }
      subscriptionNextPayment
      activity {
        id
        name
        isOnline
        supplier {
          id
          name
          slug
          additionalInformationCaptureEnabled
        }
        location {
          id
          addressLine1
          addressLine2
          city
          country
          postCode
        }
        activityType
        hideEndTimes
        subscriptionInfo
      }
      guardianId
      guestId
      finalAmount
      bookingType
      status
      numberOfBookedTickets
      bookedClasses {
        id
        name
        bookedTickets {
          originalAmount
          id
          ticketNameAtBooking
          ticketPriceAtBooking
          attendees {
            attendeeId
            attendeeName
            addons {
              perSession {
                name
                description
                price
                note
                addonOptionId
              }
              perBooking {
                name
                description
                price
                note
                quantity
                addonOptionId
              }
            }
          }
          firstSession {
            id
          }
          numberOfSessions
          sessions {
            id
            sessionStart
            sessionEnd
            sessionNumber
            totalSessions
            status
            addons {
              addonId
              name
            }
          }
          blockTrialType
          blockTrialSessionCount
          blockTrialPrice
          blockFirstPaidSessionDate
        }
      }
      bookedTickets {
        id
        ticketNameAtBooking
        ticketPriceAtBooking
        attendees {
          attendeeId
          attendeeName
          addons {
            perSession {
              name
              description
              price
              note
              addonOptionId
            }
            perBooking {
              name
              description
              price
              note
              quantity
              addonOptionId
            }
          }
        }
        ticket {
          id
          name
          price
          ticketType
        }
        firstSession {
          id
          firstSessionDate
        }
        firstSessionToBe
        numberOfSessions
        subscriptionStartTime
        subscriptionEndTime
        subscriptionDayOfWeek
        subscriptionOffDates
        subscriptionTrialSelected
        subscriptionTrialEnd
        subscriptionTrialSessionCount
        subscriptionTrialPrice
        sessions {
          id
          sessionStart
          sessionEnd
          sessionNumber
          totalSessions
          status
          isFreeTrialSession
          isPaidTrialSession
        }
      }
    }
  }
`;

export default GetBookingById;
