export enum DaysOfWeekEnum {
  Mondays = 'MONDAY',
  Tuesdays = 'TUESDAY',
  Wednesdays = 'WEDNESDAY',
  Thursdays = 'THURSDAY',
  Fridays = 'FRIDAY',
  Saturdays = 'SATURDAY',
  Sundays = 'SUNDAY',
  Weekends = 'WEEKEND',
}

export enum LocationCountryEnum {
  'United Kingdom' = 'UK',
}

export enum ActivityBookingTypeEnum {
  INSTANT = 'INSTANT',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum ActivityBookingType {
  INDIVIDUAL = 'ONE_OFF',
  BLOCK = 'BLOCK',
  COMBO = 'ONE_OFF_AND_BLOCK',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum BookingTypeEnum {
  MarketplaceActivity = 'MARKETPLACE_ACTIVITY',
}

export enum ActivityTypeEnum {
  ONE_OFF = 'ONE_OFF',
  BLOCK = 'BLOCK',
  TERM_TIME = 'TERM_TIME',
  HOLIDAYS_ONLY = 'HOLIDAYS_ONLY',
  ALL_YEAR = 'ALL_YEAR',
  ONE_OFF_AND_BLOCK = 'ONE_OFF_AND_BLOCK',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum ActivityBlockSubtypeEnum {
  ALL_DAYS = 'all_days',
  RECURRING = 'recurring',
}

export enum ActivityTimeEnum {
  AM = 'AM',
  PM = 'PM',
}

export enum AvailabilityEnum {
  IN_PAST,
  SOLD_OUT,
  AVAILABLE,
}

export enum SubscriptionTrialTypeEnum {
  NO_TRIAL = 'NO_TRIAL',
  FREE_TRIAL = 'FREE_TRIAL',
  PAID_TRIAL = 'PAID_TRIAL',
}

export enum BlockTrialTypeEnum {
  NO_TRIAL = 'NO_TRIAL',
  FREE_TRIAL = 'FREE_TRIAL',
  PAID_TRIAL = 'PAID_TRIAL',
}

export enum TicketTypeEnum {
  BLOCK = 'BLOCK',
  INDIVIDUAL = 'INDIVIDUAL',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum CheckoutPaymentMethodEnum {
  STRIPE = 'STRIPE',
  PONCHOPAY = 'PONCHOPAY',
  FREE_BOOKING = 'FREE_BOOKING',
}

export enum MinAgeRangeEnum {
  'No min age' = '0',
  '3 months' = '2',
  '6 months' = '5',
  '12 months' = '11',
  '18 months' = '17',
  '2 years' = '23',
  '3 years' = '35',
  '4 years' = '47',
  '5 years' = '59',
  '6 years' = '71',
  '7 years' = '83',
  '8 years' = '95',
  '9 years' = '107',
  '10 years' = '119',
  'Over 10 years' = '132',
}

export enum MaxAgeRangeEnum {
  'No max age' = '1201',
  '3 months' = '4',
  '6 months' = '7',
  '12 months' = '13',
  '18 months' = '19',
  '2 years' = '35',
  '3 years' = '47',
  '4 years' = '59',
  '5 years' = '71',
  '6 years' = '83',
  '7 years' = '95',
  '8 years' = '107',
  '9 years' = '119',
  '10 years' = '131',
  '18 years' = '217',
}

export enum AgeFilterTypeEnum {
  MIN = 'min',
  MAX = 'max',
}

export enum DistanceEnum {
  '1 mile' = '1',
  '2 miles' = '2',
  '5 miles' = '5',
  '10 miles' = '10',
  '20 miles' = '20',
}

export enum TimeEnum {
  'AM' = 'AM',
  'PM' = 'PM',
  'ANY_TIME' = 'ANY_TIME',
}

export enum PromoErrorsEnum {
  ERROR_BOOKING_DATE = 'This code cannot be used for this date',
  ERROR_DATE = 'This code has expired',
  ERROR_CODE_NOT_FOUND = 'The code is not recognised',
  ERROR_NOT_INCLUDED = 'Code is not valid against this booking',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ERROR_ALREADY_USED = 'Code is not valid against this booking',
  ERROR_REDEEMED = 'This code is no longer valid',
  ERROR_BLOCK_BOOKING_NOT_ELIGIBLE = 'Code cannot be applied to block activity bookings.',
  ERROR_FIRST_BOOKING_ONLY = 'Sorry, this code is for first-time bookers only',
  ERROR_BLOCK_TRIAL = 'Code not eligible for trials',
}

export enum PricingPolicyEnum {
  PER_SESSION = 'PER_SESSION',
  BLOCK_FULL_PRICE = 'BLOCK_FULL_PRICE',
  FREE = 'FREE',
}

export enum ValidFilterKeysEnum {
  ageEnd,
  ageStart,
  distance,
  dayOfTheWeek,
  latitude,
  longitude,
  time,
  suppliers,
  startingFrom,
  category,
  location,
  page,
  id,
  slug,
  isOnline,
  theme,
  seLat,
  seLng,
  nwLat,
  nwLng,
  isInstantBookOnly,
  startDate,
  endDate,
  locationString,
  searchTerm,
}

export enum SearchResultsToggleEnum {
  mapView = 'mapView',
  listingsView = 'listingsView',
  desktopView = 'desktopView',
}

export enum ActivityTimeFullEnum {
  Afternoons = 'Afternoons',
  Mornings = 'Mornings',
  AllDay = 'Sessions all day',
}

export enum SocialProofLabelEnum {
  limitedSpots = 'LIMITED SPOTS',
  popular = 'POPULAR',
  justAdded = 'JUST ADDED',
  soldOut = 'SOLD OUT',
}

export enum BasketAvailabilityErrorsEnum {
  INDIVIDUAL = 'Not enough spots left for one of the sessions',
  BLOCKS = 'Not enough spots left for the block booking',
  SUBSCRIPTION = 'Not enough spots left for the subscription',
  MISSING_ATTENDEES = 'BASKET_MISSING_ATTENDEES',
  NO_BASKET = 'BASKET_DOES_NOT_EXIST',
}

export enum ActivityBookingStatusEnum {
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
}

export enum SearchBarDropdownEnum {
  location = 'LOCATION',
  activity = 'ACTIVITY',
  when = 'DATE',
  age = 'AGE',
}

export enum StepperEnum {
  CONFIRM_ATTENDEES = 'CONFIRM_ATTENDEES',
  ANSWER_QUESTIONS = 'ANSWER_QUESTIONS',
  SELECT_ADDONS = 'SELECT_ADDONS',
  REVIEW = 'REVIEW',
}

export enum ActivityCardVariantEnum {
  MIN = 'Minified',
  FULL = 'Fullsize',
}

export enum CustomQuestionTypeEnum {
  YES_NO = 'YES_NO',
  YES_NO_INPUT = 'YES_NO_INPUT',
  TEXT_INPUT = 'TEXT_INPUT',
  CHECKBOX = 'CHECKBOX',
  SINGLE_SELECT = 'SINGLE_SELECT',
}

export enum DefaultBookingQuestionIdsEnum {
  PHOTO_VIDEO_CONSENT_ID = '78b72036-9cb4-44e4-81a9-4e65c9160f12',
  MEDICAL_INFORMATION_ID = '6104691b-5900-4fc2-bdc5-1eced46f9c45',
}

export enum BasketStatus {
  EXPIRED = 'EXPIRED',
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED',
}

export enum BlockTrialCancelScreenEnum {
  SUBMIT_CANCELLATION = 'SUBMIT_CANCELLATION',
  CANCELLATION_SUCCESS = 'CANCELLATION_SUCCESS',
  CANCELLATION_FAIL = 'CANCELLATION_FAIL',
}

export enum PebbleButtonsEnum {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
}

export enum BookingStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Moved = 'MOVED',
  OnHold = 'ON_HOLD',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export enum ActivityStatus {
  DRAFT = 'DRAFT',
  UNPUBLISHED = 'UNPUBLISHED',
  PUBLISHED = 'PUBLISHED',
}
