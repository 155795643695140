import { gql } from '@apollo/client';

const GuardianSupplierMarketingOptIns = gql`
  query guardianSupplierMarketingOptIns($guardianId: UUID!) {
    guardianSupplierMarketingOptIns(guardianId: $guardianId) {
      guardianId
      supplierOptIns {
        supplierId
        supplierName
        optedIn
      }
    }
  }
`;

export default GuardianSupplierMarketingOptIns;
