import { useQuery } from '@apollo/client';
import { GetGuardian } from 'graphql/queries';
import { Guardian } from 'types';
import { ReactNode, useEffect } from 'react';

interface WithGuardianProps {
  setGuardian(guardian: Guardian | null): void;
  children: ReactNode;
  userToken: string | null;
}

export default function WithGuardian({ children, setGuardian, userToken }: WithGuardianProps) {
  const { data } = useQuery(GetGuardian, {
    skip: !userToken,
    context: {
      headers: {
        Authorization: `${userToken}`,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (data?.guardian) {
      setGuardian(data.guardian);
    } else {
      setGuardian(null);
    }
  }, [data?.guardian, setGuardian]);

  return <>{children}</>;
}
